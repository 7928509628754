import React from 'react'
import HomeProjectHeader from '../components/Home/HomeProjectHeader/HomeProjectHeader'
import HomeAboutCard from '../components/Home/HomeAboutCard/HomeAboutCard'


const Home = () => {
    return (
        <>

        <HomeAboutCard/>
        </>
    )
}

export default Home
export const membersList = [
    //pozele trebuie sa aiba aceeasi rezolutie!!
    //1530 latime, 1950 inaltime

    {
        id:1,
        name: "Daria Rodilă",
        role: "Președinte",
        image: '/assets/members/daria_rodila_15301950.jpg',
        instagram: 'dariarodila',
        instagramLink: 'https://www.instagram.com/dariarodila'
    },

    {
        id:2,
        name: "Sebastian Stoenoiu",
        role:"Vicepreședinte",
        image: '/assets/members/sebastian_stoenoiu_15301950.jpg',
        instagram: 'sebastianstoenoiu',
        instagramLink: 'https://www.instagram.com/sebastianstoenoiu'

    },

    {
        id:3,
        name: "Andreea Ciorici",
        role:"Secretar",
        image: '/assets/members/andreea_ciorici_15301950.jpg',
        instagram: 'andreyyac',
        instagramLink: 'https://www.instagram.com/andreyyac/'
    },

    {
        id:4,
        name: "Alexia Andreica",
        role:"Trezorier",
        image: '/assets/members/alexia_andreica_15301950.jpg',
        instagram: 'andreica_alexia',
        instagramLink: 'https://www.instagram.com/andreica_alexia/'
    }
]
